import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/easter/configura-cap',
      name: 'easter-configure-cap',
      component: () => import('@/views/easter/ConfigureCap.vue'),
      meta: {
        pageTitle: 'Configura Cap',
        breadcrumb: [
          {
            text: 'Easter Slot Machine',
            active: true,
          },
          {
            text: 'Configura Cap',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slotmachine/configura-cap',
      name: 'configure-cap',
      component: () => import('@/views/ConfigureCap.vue'),
      meta: {
        pageTitle: 'Configura Cap',
        breadcrumb: [
          {
            text: 'Slot Machine Game',
            active: true,
          },
          {
            text: 'Configura Cap',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scratch/partecipanti',
      name: 'partecipantsScratch',
      component: () => import('@/views/Scratch/Partecipants.vue'),
      meta: {
        pageTitle: 'Partecipanti',
        breadcrumb: [
          {
            text: 'Gratta e vinci',
            active: true,
          },
          {
            text: 'Partecipanti',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scratch/vincitori',
      name: 'winnersScratch',
      component: () => import('@/views/Scratch/Winners.vue'),
      meta: {
        pageTitle: 'Vincitori',
        breadcrumb: [
          {
            text: 'Gratta e vinci',
            active: true,
          },
          {
            text: 'Vincitori',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scratch/check',
      name: 'checkScratch',
      component: () => import('@/views/Scratch/Check.vue'),
      meta: {
        pageTitle: 'Controlla vincitore',
        breadcrumb: [
          {
            text: 'Gratta e vinci',
            active: true,
          },
          {
            text: 'Controlla vincitore',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slotmachine/partecipanti',
      name: 'partecipants',
      component: () => import('@/views/Partecipants.vue'),
      meta: {
        pageTitle: 'Partecipanti',
        breadcrumb: [
          {
            text: 'Slot Machine Game',
            active: true,
          },
          {
            text: 'Partecipanti',
            active: true,
          },
        ],
      },
    },
    {
      path: '/easter/partecipanti',
      name: 'easter-partecipants',
      component: () => import('@/views/easter/Partecipants.vue'),
      meta: {
        pageTitle: 'Partecipanti',
        breadcrumb: [
          {
            text: 'Easter Slot Machine',
            active: true,
          },
          {
            text: 'Partecipanti',
            active: true,
          },
        ],
      },
    },
    {
      path: '/easter/vincitori',
      name: 'easter-winners',
      component: () => import('@/views/easter/Winners.vue'),
      meta: {
        pageTitle: 'Vincitori',
        breadcrumb: [
          {
            text: 'Easter Slot Machine',
            active: true,
          },
          {
            text: 'Vincitori',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slotmachine/vincitori',
      name: 'winners',
      component: () => import('@/views/Winners.vue'),
      meta: {
        pageTitle: 'Vincitori',
        breadcrumb: [
          {
            text: 'Slot Machine Game',
            active: true,
          },
          {
            text: 'Vincitori',
            active: true,
          },
        ],
      },
    },
    {
      path: '/easter/check',
      name: 'easter-check',
      component: () => import('@/views/easter/Check.vue'),
      meta: {
        pageTitle: 'Controlla vincitore',
        breadcrumb: [
          {
            text: 'Easter Slot Machine',
            active: true,
          },
          {
            text: 'Controlla vincitore',
            active: true,
          },
        ],
      },
    },
    {
      path: '/slotmachine/check',
      name: 'check',
      component: () => import('@/views/Check.vue'),
      meta: {
        pageTitle: 'Controlla vincitore',
        breadcrumb: [
          {
            text: 'Slot Machine Game',
            active: true,
          },
          {
            text: 'Controlla vincitore',
            active: true,
          },
        ],
      },
    },
    {
      path: '/avvento/check',
      name: 'checkAvvento',
      component: () => import('@/views/Avvento/Check.vue'),
      meta: {
        pageTitle: 'Controlla vincitore',
        breadcrumb: [
          {
            text: 'Calendario avvento',
            active: true,
          },
          {
            text: 'Controlla vincitore',
            active: true,
          },
        ],
      },
    },
    {
      path: '/avvento/partecipanti',
      name: 'partecipantsAvvento',
      component: () => import('@/views/Avvento/Partecipants.vue'),
      meta: {
        pageTitle: 'Partecipanti',
        breadcrumb: [
          {
            text: 'Calendario avvento',
            active: true,
          },
          {
            text: 'Partecipanti',
            active: true,
          },
        ],
      },
    },
    {
      path: '/avvento/prizes',
      name: 'prizesAvvento',
      component: () => import('@/views/Avvento/Prizes.vue'),
      meta: {
        pageTitle: 'Premi avvento',
        breadcrumb: [
          {
            text: 'Calendario avvento',
            active: true,
          },
          {
            text: 'Premi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
