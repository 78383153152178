// axios
import axios from 'axios'

const baseURL = 'https://api.amysushi.it'
//  const baseURL = 'http://localhost:8000'

export default axios.create({
  baseURL,
  headers: {
    common: {
      authorization: `Bearer ${window.getCookie('token')}`,
    },
  },
})
